.section {
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    &__body {
        padding: 2em;
    }
}