.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: rgb(39, 39, 39);
    color: rgb(255, 255, 255);
    &__action {
        display: flex;
        gap: .5em;
        &__button {
            display: flex;
            align-items: center;
            gap: .5em;
        }
    }
}