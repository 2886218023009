.projets_list {
    display: flex;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;
}

.card {
    width: 350px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__media {
        width: 100%;
        max-height: 200px;
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        &__desc {
            text-align: center;
        }
        &__spec {
            text-align: center;
        }
    }
    &__action {
        display: flex;
        justify-content: center;
    }
}