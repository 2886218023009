.skills {
    display: flex;
    flex-direction: column;
    gap: 2em;
    &__spec {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__tech {
        display: flex;
        justify-content: center;
        gap: 1em;
        flex-wrap: wrap;
        &__card {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
        }
    }
}